import React, { useState, useEffect, useCallback, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useTranslation } from 'react-i18next';

import styles from './FullDrawDown.scss'

import getColumns from './DrawdownColumns';
import DataGrid from './DataGridAG';
import { Service as DrawdownService } from '../../../services/drawdownService';
import { Search } from '../../../services/searchService';
import { User } from '../../../services/userService';

// our height so all rows are shown
// but max at 10 rows
// button is in line with top of grid, so doesn't really matter if we have that or not

function getHeight(data) {
    // top is 53
    // rows are 38 each
    // if no rows - we still want space for 1 because we display a message
    let dataRows = data?.length ? data.length : 1;
    // but we it to be unlimited - not sure we actually want to do this? We're generally not going to have more that this
    // and the scroll bar is not great as it's off the screen
    dataRows = Math.min(dataRows, 20);
    let height = (42 * dataRows) + 53;
    return height;
}

const defaultSorted = [{
    id: 1,
    desc: true
}];

function noOp() {
}

function nope() {
    return false;
}

function isFullyDrawDown(data) {
    return (data?.remainingBuyAmount * 1) === 0 || (data?.remainingSellAmount * 1) === 0;
}

// FIXME - should either of these be expandable?
// if not, then we can drop the tests from here
function isCanceledDeal(data) {
    return data.executionStatus === "CANCELED";
}

function isTerminatedDeal(data) {
    return data.executionStatus === "TERMINATE";
}

function isTradeableCompany(clientId) {
    return !!(User.user.companies.find(c => c.code == clientId));
}

// FIXME - may need to think about what happens to myDeal when we become fully drawn down.
function TheButton({deal, update}) {
    const { t } = useTranslation();


    // we also need to test if they user can trade on this company
    let theCompany = User.user.companies.find(c => c.code == deal?.clientId);
    return (
        <div className="d-grid gap-2">
        {(isFullyDrawDown(deal) || isCanceledDeal(deal) || isTerminatedDeal(deal) ||!isTradeableCompany(deal?.clientId)) ? null :
            <Button className="btn-sm td-btn-primary-light" style={ {marginTop: 2} } onClick={() => DrawdownService.setCurrent(deal) }>{t('td.fx.ticket.DRAWDOWN')}</Button>
        }
        </div>
    );
}
// FIXME - we're using this a general drawdown blotter now
// so rename it
// note also that full drawdown is going to contain the drawdown modal - we know there's only every one FullDrawdown at a time
function FullDrawDown({row, deal, update, scroll}) {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [columns, setColumns] = useState(getColumns(t));

    // we need to update when we change deals
    // but also if the deal changes - use outstanding balance to check for that
    // but we don't know when the amount changes until we get re-drawn?
    // FIXME - these tests don't work anymore . new grid doesn't re-render
    useEffect(() => {
        updateData();
        // we also need to trigger an update of the modal
        // which won't happen automatically.
        if (DrawdownService.current?.dealId === deal.dealId) {
            DrawdownService.setCurrent(deal);
        }
    }, [deal, deal.remainingBuyAmount]);

    function updateData() {
        // row.height = getHeight(deal.drawDowns || []);

        // fix the drawdowns to include the parent id
        // FIXME - this feels like a weird place to do this?
        deal.drawDowns?.forEach(element => {
            element.parent = deal.dealId;
        });
        setData(deal.drawDowns || []);
        update && update();

    }

    // update when we do a drawdown - hooking into search is easiest
    useEffect(() => {
        let done = false;
        let sub = Search.subscribe(() => {
            if (!done) {
                updateData();
            }
        });
        return () => {
            done = true;
            Search.unsubscribe(sub);
        }
    }, []);

    const doUpdate = useCallback(() => {
        // we did another drawdown
        // so do stuff
    }, [deal]);

    // we want to display a button if we are not fully drawn down.
    // the button will launch the drawdow popup - but we don't want to create one of those for every row, so it's external to us
    // which means it needs to be passed in to the grid etc.

    // if the deal isn't fulld drawndown, we add a button to do drawdowns

    // we don't want headers if we don't have data
    // but loading more drawdowns wipes out our data, so blanking this out isn't really what we want either?
    // show some spinner if we're working?

    // would be nice to get rid of the padding between the button and the grid, had gy-4, but it was messing up the vertical padding
    return (
        // add some padding above to this?
        // move 
        <Fragment>
        <div>
            <Row className="">
                <Col xs={1} sm={1} md={1} style={{ paddingRight: '0px'}}>
                    <TheButton deal={deal} update={doUpdate} />
                </Col>
                <Col xs={11} sm={11} md={11}  style={{height:getHeight(data)}} >
                    {
                        <DataGrid
                            height="100%"
                            compact={true}
                            data={data || []}
                            dataId={'creationTime'}
                            dataId2={'dealId'}
                            dataLastUpdate={false}
                            columns={columns}
                            setColumns={(c) => setColumns([...c])}
                            hideNoRows={true}
                            rowCount={false}
                            sorting={defaultSorted}
                            setSorting={(noOp)}
                            canRowExpand={nope}
                            flashRows={false}
                        />
                    }
                </Col>
            </Row>
        </div>
        </Fragment>
    );
}

export default FullDrawDown;