import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import WireDetails from '../../RequestForQuote/WireDetails';
import { AmountInput, DateInput } from '../../input';

import styles from './DrawDown.scss'

// FIXME - we might not need all of these?
import { useForm } from '../../../utils/useForm';
import { useModel } from '../../RequestForQuote/useModel';

import { User } from '../../../services/userService';
import { Service as DealService } from '../../../services/dealService';
import { fixAccountSelection } from '../../RequestForQuote/useAccounts';

import WireMemoInput from '../../input/WireMemoInput';
import { Search } from '../../../services/searchService';

import utils from '../../../utils';

const TODAY = new Date();

// FIXME
// change our parameters - they don't make sense if we're a modal
export default function DrawDown({ deal, close }) {
    const { t } = useTranslation();
    // FIXME - prerequisite is drawdown permission - shouldn't have got here if we don't have it
    const { permissions } = User.user;

    // model is set from the deal
    const [model, setModel] = useState(getModelFromDeal(deal));

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        validate();
        // not sure this makes sense
        updateModelFromDeal(deal);
    }, [deal]);

    // this will be called when we get the updated deal from search
    function updateModelFromDeal(d) {
        // we change the current model and then create a new one to update state
        model.remainingBuyAmount = d.remainingBuyAmount;
        model.remainingSellAmount = d.remainingSellAmount;
        setModel({...model});
    }
    // update when we do a drawdown - hooking into search is easiest
    useEffect(() => {
        let done = false;
        let sub = Search.subscribe(() => {
            if (!done) {
                // update the model with the remaining amounts
                // don't call updateModel, because we don't really want validation
                // also we need the current value of model
                // we do this on clear, which is called when we close the popup, so probably don't need it anymore
                // setModel((prev) => ( {...prev, remainingBuyAmount: deal.remainingBuyAmount, remainingSellAmount: deal.remainingSellAmount} ));
                // we kind of need to clear at this point though - we're going to get a validation and it might fail
                // but clear calls newDeal, which makes the popup close, so we can't do that
                // clear();
                // so better to update when deal state changes
            }
        });
        return () => {
            done = true;
            Search.unsubscribe(sub);
        }
    }, []);

    function doClose() {
        clear();
        close();
    }

    // if we call useAccounts, it's going to trigger effects when the company changes
    // and because we don't have a company in state, that would be bad.

    // FIXME - copied form useAccounts - FIXME
    function handleAccountChange(name, ssid, accounts) {
        const account = accounts.filter(acc => acc.ssid == ssid)[0];
        model[name] = account;
        updateModel(model);
    };

    const DATE_START_DATE = parseISO(model.optionStartDate);
    const START_DATE = TODAY > DATE_START_DATE ? TODAY : DATE_START_DATE;
    const END_DATE = parseISO(model.optionEndDate);

    // if they scroll this out of view, it get's created again
    // so check what's current in our service

    const company = User.user.companies.find(c => c.code == model.company);

    // FIXME - we don't need the complexity here anymore?
    function getModelFromDeal(d, force) {
        if (!d) {
            return d;
        } else {
            let theCompany = User.user.companies.find(c => c.code == d.clientId);
            if (theCompany) {
                let theDeal = {
                    company: d.clientId,
                    dealId: d.dealId,
                    buyCurrency: d.buyCurrency,
                    sellCurrency: d.sellCurrency,
                    rate: parseFloat(d.buyAmount) / parseFloat(d.sellAmount),
                    optionStartDate: d.optionStartDate,
                    optionEndDate: d.endDate,
                    remainingBuyAmount: d.remainingBuyAmount,
                    remainingSellAmount: d.remainingSellAmount,
                    tradeDate: d.tradeDate,
                    isBuy: d.buySell === 'BUY',
                    dealtRate: parseFloat(d.dealtRate),
                    buyAccount: fixAccountSelection(null, theCompany.buyAccounts.filter(a => a.ccy == d.buyCurrency)),
                    sellAccount: fixAccountSelection(null, theCompany.sellAccounts.filter(a => a.ccy == d.sellCurrency)),
                    scroll: !force
                };
                return theDeal;
            } else {
                return {};
            }
        }
    }

    // start from the current model value
    // because this function is captured in closures all over the place...
    function clearModel() {
        setModel(model => {
            model.buyAmount = undefined;
            model.sellAmount = undefined;
            model.valueDateDate = undefined;
            let theCompany = User.user.companies.find(c => c.code == model.company);
            model.buyAccount = fixAccountSelection(null, theCompany.buyAccounts.filter(a => a.ccy == model.buyCurrency));
            model.sellAccount =  fixAccountSelection(null, theCompany.sellAccounts.filter(a => a.ccy == model.sellCurrency));
            return {...model};
        });
     }

    // called when we close the popup or press the clear button
    function clear() {
        setHasSubmitted(false);
        setWorking(false);
        // deal here is the old deal - we want to use the new one that we get when things update
        // what we really want to do is clear out the entry fields - the remaining balances will have been updated
        // delay so we don't get a validation error because working won't update until the next render
        setTimeout(() => {
            // this function has a closure version of the model which might have old values in it
            clearModel();
        }, 10);
    }

    // listen for deal state
    // we only copy this because we need to trigger a re-render
    const [dealState, setDealState] = useState(DealService.state);
    useEffect(() => {
        let done = false;
        // FIXME -we are going to get notifications when a user does SPOT etc. with this open
        // we probably don't want to trigger behaviour based on that?
        let sub = DealService.subscribe(() => {
            if (!done) {
                // we want to trigger a render, but not sure we actually need to look at the deal?
                setDealState(DealService.state);
                // when the deal is done we want to update the totals
                // this happens when we close the popup
                if (DealService.isInitialState()) {
                    clear();
                }
            }
        });
        return (() => {
            done = true;
            DealService.unsubscribe(sub);
        });
    }, []);


    const { handleChange, handleConnectedChange } = useModel(model, updateModel);
    const { fieldErrors: errors, setFieldError } = useForm(validate);

    // we can pass in a model if we don't think state model is up to date
    function validate(fields, m) {
        // we don't want to validate when we get the response back from the deal service
        if (working) { 
            return;
        }
        fields = fields || errors;
        let data = m || model;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError('error.fields');
        } else {
            if (!(data.buyAmount || data.sellAmount)) {
                setError('error.no.amount');
            } else if (!data.buyAccount || !data.sellAccount) {
                setError('lbl.selectOption');
            } else if (!data.valueDateDate) {
                setError('error.invalid.date');
            } else {
                // check amount is less than remaining balance.
                // Note - only check main currency.  e.g if isBuy, only check buy
                let amount = data.isBuy ? data.buyAmount : data.sellAmount;
                let remainingAmount = data.isBuy ? data.remainingBuyAmount : data.remainingSellAmount;
                if (parseFloat(amount) > parseFloat(remainingAmount)) {
                    setError('error.invalid.amount');
                } else {
                    setError(false);
                }
            }
        }
    }

    function updateModel(m) {
        let theModel = {...m}
        setModel(theModel);
        // validation works on the current model, and on the current fields
        // if we are passing in a cleared model above, this validate won't see that 
        // the set should trigger an update of the field contents, but that won't trigger validation
        // caller should trigger validation in a timeout - doing it here would cause too much noise
        validate(null, theModel);
        
    }

    function doDrawdown() {
        if (!error) {
            setWorking(true);
            // order is important because model.company is the code
            DealService.drawdown({ ...model, company: company });
            // close();
        }
        // submitted is set either way - it controls display of the errors
        setHasSubmitted(true);   
    }

    // FIXME - need to know if the value is invalid or not before we convert
    // is NaN test sufficient?
    function getOtherAmount(amount, name) {
        if (!amount || isNaN(amount)) {
            return '';
        }

        // FIXME this might flip if we're a buy deal vs sell deal?
        if (name == 'buyAmount') {
            return (parseFloat(amount) / model.rate).toFixed(2).toString();
        } else {
            return (parseFloat(amount) * model.rate).toFixed(2).toString();
        }
    }

    // takes us a render cycle to load the copmany/model
    if (!company) {
        return null;
    }
    return (
        <div className={styles.DrawDown}>
            <Form >
                <fieldset disabled={!DealService.isInitialState()} style={{ paddingBottom: '1.5em' }}>
                    <Row>
                        <h4>{t('td.fx.ticket.drawdown.available.balances')}</h4>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <h4>{model.buyCurrency} {t('td.fx.ticket.drawdown.remaining.buy.amount')} {utils.format.formatAmount(model.remainingBuyAmount)}</h4>
                        </Col>
                        <Col md={3}>
                            <h4>{model.sellCurrency} {t('td.fx.ticket.drawdown.remaining.sell.amount')} {utils.format.formatAmount(model.remainingSellAmount)}</h4>
                        </Col>
                        <Col md={3}>
                            <h4>{t('td.fx.ticket.drawdown.start.date')} {model.optionStartDate}</h4>
                        </Col>
                        <Col md={3}>
                            <h4>{t('td.fx.ticket.drawdown.end.date')} {model.optionEndDate}</h4>
                        </Col>
                    </Row>
                    <hr className="td-thin-divider-line-1" style={{ padding: '1em 0' }} />
                    <Row className={'g-3'} key={1}>
                        <Col xs={12} sm={12} md={2}>
                            <Row className="g-2">
                                <Form.Group>
                                    <Form.Label>{t('td.fx.ticket.label.ValueDate')}</Form.Label>
                                    <DateInput
                                        className={''}
                                        min={START_DATE}
                                        max={END_DATE}
                                        name={'valueDateDate'}
                                        value={model.valueDateDate}
                                        update={(date) => handleChange(date, 'valueDateDate')}
                                        setFieldError={setFieldError}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <Row className="g-2">
                                <Form.Group>
                                    <Form.Label>{t('td.fx.ticket.drawdown.buy')} {model.buyCurrency}</Form.Label>
                                    <AmountInput
                                        name={'buyAmount'}
                                        value={model.buyAmount}
                                        update={(val) => handleConnectedChange('buyAmount', val, 'sellAmount', getOtherAmount)}
                                        setFieldError={setFieldError}
                                        errorPrefix={'td.fx.ticket.drawdown.'}
                                        minValue={0.01}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <Row className="g-2">
                                <Form.Group>
                                    <Form.Label>{t('td.fx.ticket.drawdown.sell')} {model.sellCurrency}</Form.Label>
                                    <AmountInput
                                        name={'sellAmount'}
                                        value={model.sellAmount}
                                        update={(val) => handleConnectedChange('sellAmount', val, 'buyAmount', getOtherAmount)}
                                        setFieldError={setFieldError}
                                        errorPrefix={'td.fx.ticket.drawdown.'}
                                        minValue={0.01}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <Row className="g-3">
                                <Form.Group>
                                    <Form.Label>
                                        {t('td.fx.ticket.lbl.to')}
                                        {model.buyAccount?.wire &&
                                            <WireDetails wire={model.buyAccount.wire} />
                                        }
                                    </Form.Label>

                                    <Form.Select
                                        className={'form-control'}
                                        name="buyAccount"
                                        value={model.buyAccount?.ssid || ''}
                                        placeholder={t('td.fx.ticket.lbl.selectOption')}
                                        onChange={(e) => handleAccountChange('buyAccount', e.target.value, company.buyAccounts)}
                                    >
                                        {model.buyAccount ? null : <option></option>}
                                        {company?.buyAccounts?.filter(a => a.ccy == model.buyCurrency).map(a =>
                                            <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <Row className="g-3">
                                <Form.Group>
                                    <Form.Label>{t('td.fx.ticket.lbl.from')}</Form.Label>
                                    <Form.Select
                                        className={'form-control'}
                                        name="sellAccount"
                                        value={model.sellAccount?.ssid || ''}
                                        placeholder={t('td.fx.ticket.lbl.selectOption')}
                                        onChange={(e) => handleAccountChange('sellAccount', e.target.value, company.sellAccounts)}
                                    >
                                        {model.sellAccount ? null : <option></option>}
                                        {company?.sellAccounts?.filter(a => a.ccy == model.sellCurrency).map(a =>
                                            <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <Row className="g-3">
                                <Form.Group>
                                    <Form.Label>{t('td.fx.ticket.lblWirePaymentDetails')}</Form.Label>
                                    <WireMemoInput
                                        name={'wireMemo'}
                                        value={model.wireMemo || ''}
                                        update={(val) => handleChange(val, 'wireMemo')}
                                        setFieldError={setFieldError}
                                        errorPrefix={'td.validation.'}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                </fieldset>
                <Row className="gy-4">
                    <Col xs={12} sm={12}>
                        <Form.Label className={'td-error'} hidden={!error || !hasSubmitted}>{error && t('td.fx.ticket.' + error)}</Form.Label>
                        <Row className="gy-4 g-3">
                            <Col xs={12} sm={12} md={3} lg={2}>
                                <div className="d-grid gap-2">
                                    <Button disabled={DealService.isWorking()} className="btn td-btn-secondary-clear" onClick={doClose}>{t('td.fx.ticket.drawdown.close')}</Button>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={2}>
                                <div className="d-grid gap-2">
                                    <Button disabled={DealService.isWorking()} className="btn td-btn-secondary-clear" onClick={clear}>{t('td.fx.ticket.NEWTRADE')}</Button>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={2}>
                                <div className="d-grid gap-2">
                                    <Button disabled={!DealService.isInitialState() || working} className="btn td-btn-primary-light" onClick={doDrawdown}>{t('td.fx.tradeSettlement.btn.submit')}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            {/*
                // we don't want this anymore
                <hr className="td-thin-divider-line-1" style={{ padding: '1em 0' }} />
                <FullDrawDown row={myRow} deal={deal} />
            */}
        </div>
    );
}
