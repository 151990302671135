import React, { useState, useEffect } from 'react';

import i18next from 'i18next';
import { service as io } from './streamingService';

import { User } from './userService';

import { Observer } from '../utils';

let nextRequestId = (new Date()).valueOf();
function getNextRequestId(userId) {
    // the user id is already include in the communication layer 
    // - in the session, and associated with the socket.
    // So we're only worried about uniqueness from our own transactions
    // - so timestamp should be enough
    var requestId = userId + "TD" + String(nextRequestId);
    nextRequestId++;
    return requestId;
};


function DealService() {
    const STATES = { 
        INITIAL: 'Initial',
        // we sent it
        OPEN: 'Open',
        // back end got it
        OPEN_SENT: 'OpenSent',
        // dealer picked it up
        OPENED: 'Opened',
        // no quote for us (also pretty much all other failure conditions
        WITHDRAWN: 'Withdrawn',
        // here it is 
        QUOTE: 'Executable',
        // we sent it back
        EXECUTE: 'Execute',
        // back end got it
        EXECUTE_SENT: 'ExecuteSent',
        // back end did it
        EXECUTED: 'Executed',
        // back end didn't like it
        EXECUTE_REJECTED: 'ExecuteRejected',
        // back end did some more (is this only for settle only?)
        SETTLED: 'Settled',
        // we changed our mind 
        REJECTED: 'ClientClose',
        TIMEOUT: 'Timeout',
        CLIENT_CLOSED: 'ClientClosed'
    };
    // FIXME - smells bad
    this.STATES = STATES;

    this.state = this.STATES.INITIAL;

    this.isWorking = function () {
        switch (this.state) {
            case this.STATES.OPEN:
            case this.STATES.OPEN_SENT:
            case this.STATES.OPENED:
            case this.STATES.QUOTE:
            case this.STATES.EXECUTE_SENT:
            case this.STATES.EXECUTE:
                return true;
            default:
                return false;
            }
    };

    // FIXME - not sure all this stuff belongs at the top level
    // in the old code we had this.model.*
    this.newTrade = function () {
        // clear our local request
        this.request = null;
        this.response = null;
        this.state = this.STATES.INITIAL;
        this.settling = false;
        this.doingDrawdown = false;
        this.publish();
    };

    this.invalidCcyForInverseQuote =['AUD', 'EUR', 'GBP', 'NZD'];

    this.isQuotingState = function () {
        switch (this.state) {
        case this.STATES.OPEN:
        case this.STATES.OPEN_SENT:
        case this.STATES.OPENED:
        case this.STATES.QUOTE:
            return true;
        default:
            return false;
        }
    };

    this.isInitialState = function () {
        return this.STATES.INITIAL === this.state;
    };

    this.isTradeExecuted = function () {
        return this.state === this.STATES.EXECUTED || this.state === this.STATES.SETTLED;
    };

    this.isTradeSettled = function () {
        return this.state === this.STATES.SETTLED;
    };

    
    this.isQuoteFailed = function () {
        return this.state === this.STATES.WITHDRAWN || this.state === this.STATES.EXECUTE_REJECTED;
    };

    // this may also be used if the button is labeled book and settle
    // also disable when our quote times out
    this.canBook = function () {
        return this.state === this.STATES.QUOTE && this.response.quoteCountdown;
    };

    this.canReject = function () {
        return this.state === this.STATES.OPENED || this.state === this.STATES.QUOTE;
    };

    this.canCancel = function () {
        return this.state === this.STATES.OPEN    ||
            this.state === this.STATES.OPEN_SENT  ||
            this.state === this.STATES.OPENED;
    };

    this.waitingForQuote = function () {
        return this.state === this.STATES.INITIAL ||
            this.state === this.STATES.OPEN       ||
            this.state === this.STATES.OPENED     ||
            this.state === this.STATES.OPEN_SENT; 
    };

    // FIXME
    this.canQuote = function () {
        return (this.state === this.STATES.INITIAL);//  && this.userInfo.canTrade && !this.settling && this.channel.connected && utilities.isBranchOpen());
    };

    this.isValidInverse = function(cur) {
        return this.invalidCcyForInverseQuote.indexOf(cur) == -1;
    };

    this.canQuoteInverse = function (buyCurrency, sellCurrency) {
        if (!this.canQuote()) {
            return false;
        }
        return this.isValidInverse(buyCurrency) && this.isValidInverse(sellCurrency);
    };


    // FIXME?
    this.canClear = function () {
        return (!this.isWorking()); //  && this.userInfo.canTrade  && utilities.isBranchOpen());
    };

    // e.g. can we press the settle button in the ticket
    // user this.userInfo.canSettle to test user permissions
    this.canSettle = function () {
        return (this.state === this.STATES.INITIAL && this.settling); //  && utilities.isBranchOpen());
    };

    // return yyyy-mm-dd from a date object - if we user .toJSON() timezones mess it up
    function getDateString(value) {
        if (value instanceof Date && value > 0) {
            return `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, '0')}-${(value.getDate()).toString().padStart(2, '0')}`;
        } else {
            return value;
        }
    }

    function buildRequest(data) {
        // FIXME - format the data correctly e.g. dates
        // reduce the noise in the account objects

        const canSettle = User.user.permissions.SETTLE;

        let request = { 
            state: STATES.OPEN,
            wantFwdPoints: User.user.permissions.SHOWFWDPOINTS,
            wantMidPoints: User.user.permissions.SHOWMIDPOINTS,
            action: data.product, 
            buyCurrency: data.buyCurrency,
            sellCurrency: data.sellCurrency,
            ...(data.buyAccount && canSettle && {buyAccount: getSsi(data.buyAccount)}),
            ...(data.sellAccount && canSettle && {sellAccount: getSsi(data.sellAccount)}),
            ...(data.nearBuyAccount && {nearBuyAccount: getSsi(data.nearBuyAccount)}),
            ...(data.nearSellAccount && {nearSellAccount: getSsi(data.nearSellAccount)}),
            ...(data.nearValueDateType && {nearValueDateType: data.nearValueDateType}),
            ...(data.nearValueDateDate && {nearValueDateDate: getDateString(data.nearValueDateDate)}),
            ...(data.farBuyAccount && {farBuyAccount: getSsi(data.farBuyAccount)}),
            ...(data.farSellAccount && {farSellAccount: getSsi(data.farSellAccount)}),
            ...(data.farValueDateType && {farValueDateType: data.farValueDateType}),
            ...(data.farValueDateDate && {farValueDateDate: getDateString(data.farValueDateDate)}),
            ...(data.valueDateType && {valueDateType: data.valueDateType}),
            ...(data.valueDateDate && {valueDateDate: getDateString(data.valueDateDate)}),
            ...(data.optionStartDateType && {optionStartDateType: data.optionStartDateType}),
            ...(data.optionStartDateDate && {optionStartDateDate: getDateString(data.optionStartDateDate)}),
            ...(data.optionEndDateType && {optionEndDateType: data.optionEndDateType}),
            ...(data.optionEndDateDate && {optionEndDateDate: getDateString(data.optionEndDateDate)}),
            ...(data.product != 'SWAP' && {buyAmount: data.buyAmount ? data.buyAmount : 0}),
            ...(data.product != 'SWAP' && {sellAmount: data.sellAmount ? data.sellAmount : 0}),
            ...(data.product === 'SWAP' && {nearBuyAmount: data.nearBuyAmount ? data.nearBuyAmount : 0}),
            ...(data.product === 'SWAP' && {nearSellAmount: data.nearSellAmount ? data.nearSellAmount : 0}),
            ...(data.product === 'SWAP' && {farBuyAmount: data.farBuyAmount ? data.farBuyAmount : 0}),
            ...(data.product === 'SWAP' && {farSellAmount: data.farSellAmount ? data.farSellAmount : 0}),
            inverseQuote: data.inverseQuote,
            requestId: getNextRequestId(User.user.userId),
            tradeMemo: data.tradeMemo,
            ...(data.farTradeMemo && {farTradeMemo: data.farTradeMemo }),
            wireMemo: data.wireMemo,
            company: {
                code: data.company.code,
                name: data.company.name,
                desk: data.company.deskName
            },
            tradeOnly: User.canSettle(),
            userName: User.user.fullName 
        };
        return request;
    }

    // listener managment
    this.processEvents = (data, cb) => {
        // console.log('DealService.processEvents', data);
        if (data) {
            // confirm this is the same request as us
            // if it's not, we don't want it.  Either it's an old request we shouldn't be getting events fo
            // or it's another login of the same user.
            // Ideally we wouldn't get any events like this ...
            // FIXME - should the working state test apply to everything?
            // or maybe the executed/settled transition won't be picked up the
            // request might have already been dropped
            if (data.requestId && (data.requestId.startsWith(this.request?.requestId))) {
                cb(data);
            } else {
                // that's not great.  We shouldn't get events for other transactions
            }
        }
    };

    this.createQuoteListener = function(f) {
        this.removeQuoteListener();
        this.quoteListenerHandle = io.createListener('quote', (data) => this.processEvents(data, f));
    };

    this.removeQuoteListener = function () {
        if (this.quoteListenerHandle) {
            // FIXME - why are we exposing the socket
            io.socket?.removeListener('quote', this.quoteListenerHandle);
            this.quoteListenerHandle = false;
        }
    };


    this.getQuote = function (data) {
        // change the state and publish
        this.state = this.STATES.OPEN;
        let request = buildRequest(data);
        // pretty sure we can't just pass this.processEvents because of the this pointer?
        this.createQuoteListener((data) => this.processQuoteEvents(data));
        // keep the current caller's request?
        // who should have ownership here?
        this.request = data;
        data.requestId = request.requestId;

        // console.log('sending deal message');
        io.emit('deal', request);
        this.publish();
    };

    this.processQuoteEvents = function (data) {
        // copy the data, this is safe for null response
        this.response = {...this.response, ...data};
        this.state = this.response.state;

        // if the state was withdraw, or reject/cancel
        // clear out the quote data
        switch (this.response.state) {
        case this.STATES.OPENED:
        case this.STATES.WITHDRAWN:
        case this.STATES.EXECUTE_REJECTED:
        case this.STATES.CLIENT_CLOSED:
            // wipe out the quote data, but keep the error code.
            let quote;
            if (this.response.quote) {
                quote = {errorCode: this.response.quote.errorCode}; 
            }
            this.response.quote = quote;
            this.cancelTimeout();
            break;
        default:
            break;
        }

        // console.log('DS state change:', this.state);
        // now, or after this block?
        this.publish();

        // now what about the timeout?
        // start counting down, and decrement the remaining seconds
        if (this.response.quote && this.response.quote.timeToExpiry) {

            // in case we have one running already - why would we?
            this.cancelTimeout();
            this.response.quoteCountdown = parseInt((this.response.quote.timeToExpiry / 1000), 10) + 1;

            var self = this;
            var decrementQuoteTimeout = function () {
                self.response.quoteCountdown = (self.response.quoteCountdown - 1) / 1;
                // console.log('decrement timeout: ', self.response.quoteCountdown);
                if (!self.response.quoteCountdown || self.response.quoteCountdown < 0) {
                    // quote has expired - but defer this and re-test in case we're in the middle of accepting etc.
                    self.timeoutTimer= setTimeout(function () {
                        if (!self.response.quoteCountdown && (self.state === self.STATES.QUOTE)) {
                            self.timeoutQuote();
                        }
                    }, 50);
                } else {
                    self.timeoutTimer = setTimeout(decrementQuoteTimeout, 1000);
                }
                self.publish();
            };
            decrementQuoteTimeout();
        }
    };

    // could be multiple subscribers to deal service
    // they're all going to pick up the state change
    // so add something to make sure they know it might not be them
    this.settle = function (deal) {
        // FIXME - should probably not bet this?
        this.state = this.STATES.OPEN;
        var data = {
            settling: true,
            state: this.state,
            action: deal.product, 
            buyAccount: getSsi(deal.buyAccount),
            sellAccount: getSsi(deal.sellAccount),
            dealId: deal.dealId,
            requestId: getNextRequestId(User.user.userId),
            tradeMemo: deal.tradeMemo,
            wireMemo: deal.wireMemo,
            company: {
                code: deal.company.code,
                name: deal.company.name,
                desk: deal.company.deskName
            },
            userName: User.user.fullName 
        };
        this.settling = true;
        this.request = data;

        // maybe we want to use a different function?
        this.createQuoteListener((data) => this.processSettlementEvents(data));

        io.emit('deal', data);
        this.publish();
    };

    // reduce the noise in SSIs - e.g. wire info
    function getSsi(ssi) {
        return {
            ccy: ssi.ccy,
            ssid: ssi.ssid + "",
            name: ssi.name
        };
    }

    this.processSettlementEvents = function(data) {
        // copy the request as well so we have the dealId
        this.response = {...this.request, ...data};
        this.state = this.response.state;

        // do we need to do anything when we're done
        // or if we fail - we need to be careful not to leave the service in an invalid state
        this.publish();
    };

    // could be multiple subscribers to deal service
    // they're all going to pick up the state change
    // so add something to make sure they know it might not be them
    this.drawdown = function (deal) {
        // FIXME - should probably not bet this?
        this.state = this.STATES.OPEN;
        var data = {
            requestId: getNextRequestId(User.user.userId),
            state: this.state,
            action: 'DRAWDOWN', 
            dealId: deal.dealId,
            buyAccount: getSsi(deal.buyAccount),
            sellAccount: getSsi(deal.sellAccount),
            buyAmount: deal.buyAmount, 
            sellAmount: deal.sellAmount,
            valueDateDate: getDateString(deal.valueDateDate),
            tradeMemo: deal.tradeMemo,
            wireMemo: deal.wireMemo,
            isBuy: deal.isBuy,
            company: {
                code: deal.company.code,
                name: deal.company.name,
                desk: deal.company.deskName
            },
            userName: User.user.fullName

        };
        // FIXME - kludge to hide the modal
        // problem is we don't always want to hide it
        // so we need to distinguish between this and actually settling
        this.settling = true;
        this.doingDrawdown = true;
        this.request = data;

        // maybe we want to use a different function?
        this.createQuoteListener((data) => this.processDrawdownEvents(data));

        io.emit('deal', data);
        this.publish();
    };

    this.processDrawdownEvents = function(data) {
        // console.log('process drawdown events', this.request, data);
        this.response = {...this.request, ...data};
        this.state = this.response.state;

        // do we need to do anything when we're done
        // or if we fail - we need to be careful not to leave the service in an invalid state
        this.publish();
    };


    // we have the state at this point
    // FIXME - check our state transition 
    // - we can't do this if we don't have a quote.
    this.bookAndSettle = function () {
        if (this.settling) {
            // we don't want to book and settle, we just want to settle
            // FIXME - let's just have the caller call the right function?
            // this.settle();
        } else {
            this.state = this.STATES.EXECUTE;
            this.cancelTimeout();
            var data = {};
            data.state = this.state;
            data.requestId = this.request.requestId;

            // do we need anything else?
            io.emit('deal', data);
            this.publish();
        }
    };

 

    this.timeoutQuote = function () {
        // console.log('DS timeoutQuote');
        var data = {};
        data.state = this.STATES.TIMEOUT;
        data.requestId = this.request.requestId;
        io.emit('deal', data);
        this.state = this.STATES.TIMEOUT;
        // console.log('DS state is', this.state);
        this.publish();
    };

    this.cancelTimeout = function () {
        if (this.timeoutTimer) {
            clearTimeout(this.timeoutTimer);
        }
    };

    // 
    this.rejectQuote = function () {
        var errorKey = this.canBook() ? 'rejected' : 'cancelled';
        this.state = this.STATES.REJECTED;
        this.cancelTimeout();
        var data = {};
        data.state = this.state;
        data.requestId = this.request.requestId;
        io.emit('deal', data);
        this.removeQuoteListener();

        // this resets the state, because it's in the model...
        this.newTrade();
        // FIXME - do we care that we rejected now?
        // this.model.error = errorKey;
    };

    // we want observers to be fired - but some only for new deals, not quotes
    // we will publish state changes, and observers can look at it?
    Observer.mixin(this);

    this.killQuote = function() {
        this.state = this.STATES.WITHDRAWN;
        // wipe out the quote data, but keep the error code.
        let quote =  {errorCode: '1'}; 

        this.response.quote = quote;
        this.cancelTimeout();
        this.publish();
    };

    // subscribe to the user service - we get updates on our connection there
    User.subscribe(() => {
        if (User.connected) {
            // do nothing
        } else {
            // whoops we're disconnected
            // if we are in the middle of a transaction we better kill it
            if (this.isWorking()) {
                this.killQuote();
            }
        }
    });
}

// FIXME - should we extract this?
function useDealService(model, setModel, clear, setHasSubmitted) {
    // listen for deal state
    const [dealState, setDealState] = useState(Service.state);
    useEffect(() => {
        let done = false;
        let sub = Service.subscribe(() => {
            if (!done) {
                setDealState(Service.state);
            }
        });
        return (() => {
            done = true;
            Service.unsubscribe(sub);
        });
    }, []);

    useEffect(() => {
        // FIXME - does it actually make sense to separate this behaviour from the useEffect above
        // not sure it does
        if (Service.isInitialState()) {
            // FIXME - we don't really want this all the time
            // - if they canceled or rejected a trade, we want to leave the data alone
            // - so we need to understand the previous state
            if (model.done) {
                clear?.();
            } else {
                // still clear the submit state
                setHasSubmitted?.(false);
            }
        } else if (Service.isTradeExecuted()) {
            // if we've done a trade, push something into the model so we know.
            // we don't display this from here but we will use it to decide
            // if transition back to initial state triggers a clear
            // console.log('DealService setModel');
            setModel?.({done: true, ...model});
        }
    }, [dealState]);


}
const Service = new  DealService();

export {useDealService, Service};

