import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { OnePortal } from '../../services/onePortalService';
import { User } from '../../services/userService';

export default function AlreadyRegistered({ open, setOpen, success, working, resendEmail }) {
    const { t } = useTranslation();
    const [returnError, setReturnError] = useState();
    let resendTextKey = success ? 'td.fx.resend.oneportal.email.again' : 'td.fx.resend.oneportal.email';

    useEffect(() => {
        let done = false;
        let sub = OnePortal.subscribe((data) => {
            console.log('got one portal publish');

            if (!done) {
                setReturnError(OnePortal.error);
            }
        });
        return () => {
            done = true;
            OnePortal.unsubscribe(sub);
        }
    }, []);

    function logout() {
        User.logout();
    }

    return (
        <Modal show={open} centered className="td-modal modal-xl">
            <Modal.Header><h2>{t('td.fx.op')}</h2></Modal.Header>
            <Modal.Body className="td-modal-body-shown">
                { returnError ? <>
                        <h3>{t('td.fx.op.error.heading')}</h3>
                        <p>{t('td.fx.op.error.try.again')}</p>
                        <p>{t(User.deskSpecificText('td.fx.op.login.needhelp'))}</p>
                    </> 
                    :
                    <>
                        <p>{t('td.fx.op.login.op')}</p>
                        { !OnePortal.finishedSetup && <p>{t(User.deskSpecificText('td.fx.op.login.needhelp.oneportal'))}</p>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                { returnError ? <Button disabled={working} onClick={() => setOpen(false)} className="btn td-btn-secondary-clear">{t('td.fx.ticket.drawdown.close')}</Button>
                    :
                    <>
                        { !OnePortal.finishedSetup && <Button disabled={working} onClick={() => resendEmail()} className="btn td-btn-secondary-clear">{t(resendTextKey)}</Button> }
                        <Button disabled={working} onClick={() => logout()} className="btn td-btn-primary-light">{t('td.fx.relogin.oneportal')}</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    )
}